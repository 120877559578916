export interface PlaygroundConfigProps {
  shouldShowGlobalHeader: boolean;
  parentSupportsSubscriptions: boolean;
  runTelemetry: boolean;
}

export class ExplorerConfigUtils {
  private static updateQueryStrings = (
    url: string,
    props: PlaygroundConfigProps
  ) => {
    const urlObject = new URL(url);
    urlObject.searchParams.set(
      "shouldShowGlobalHeader",
      props.shouldShowGlobalHeader.toString()
    );
    urlObject.searchParams.set(
      "parentSupportsSubscriptions",
      props.parentSupportsSubscriptions.toString()
    );
    urlObject.searchParams.set("runTelemetry", props.runTelemetry.toString());
    return urlObject.toString();
  };

  public static updateIframeSrc = (props: PlaygroundConfigProps) => {
    const iframe = document.getElementsByTagName("iframe")[0];
    iframe.src = this.updateQueryStrings(iframe.src, props);
  };
}
