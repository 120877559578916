import { Amplify } from "aws-amplify";

// This is the default configuration for the playground
// and is only used when running locally. The actual
// configuration is loaded from the config.json file
// in the root of the project. This gets generated
// by the CDK during deployment.
const DEFAULT_CONFIG = {
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_Ee2W67vEb",
  aws_user_pools_web_client_id: "4m5pb03af071lknj812rn08dh2",
};

export const configure = (): boolean => {
  if (window.location.hostname === "localhost") {
    Amplify.configure(DEFAULT_CONFIG);
    return true;
  }
  const response = fetch("./config.json").then((response) => {
    return response.json();
  });
  response.then((config) => {
    Amplify.configure(config);
  });

  // wait 1 second for the fetch to complete
  setTimeout(() => {
    console.log("Configurations loaded");
  }, 1000);

  return true;
};
