import React, { ReactElement, useEffect, useState } from "react";
import { ApolloExplorer } from "@apollo/explorer/react";
import { EmbeddableExplorerOptions } from "@apollo/explorer/src/react/ApolloExplorer";
import AccepPolicy from "./AcceptPolicyModal";
import { WithAuthenticatorProps } from "@aws-amplify/ui-react";
import styles from "../styles.module.css";

export interface BrandedApolloExplorerProps<
  TExploreProps = EmbeddableExplorerOptions
> {
  brandLogo: ReactElement;
  exploreProps: TExploreProps;
  auth: WithAuthenticatorProps;
  [key: string]: any;
}

const BrandedApolloExplorer: React.FC<BrandedApolloExplorerProps> = (props) => {
  const { brandLogo } = props;

  const [apModalIsOpen, setapModalIsOpen] = useState<boolean>(true); // Accept Policy Modal Is Open
  const [hasAcceptedPolicy, setHasAcceptedPolicy] = useState<boolean>(false);

  useEffect(() => {
    const acceptedPolicy = localStorage.getItem("hasAcceptedPolicy");
    if (acceptedPolicy) {
      setHasAcceptedPolicy(true);
      setapModalIsOpen(false);
    }

    // @ts-ignore
    props.exploreProps.initialState.headers = {
      Authorization: `Bearer ${props.auth
        .user!.getSignInUserSession()!
        .getAccessToken()
        .getJwtToken()}`,
    };
  }, []);

  const closeModal = () => {
    setapModalIsOpen(false);
    setHasAcceptedPolicy(true);
    localStorage.setItem("hasAcceptedPolicy", "true");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.branding}>
          {React.cloneElement(brandLogo, { className: styles.brandingIcon })}
          <span className={styles.betaLabel}>Beta</span>
        </div>
        <div className={styles.admin}>
          <button className={styles.button} onClick={props.auth.signOut}>
            Log Out
          </button>
        </div>
      </div>
      <ApolloExplorer {...props.exploreProps} className={styles.playground} />
      {!hasAcceptedPolicy && (
        <AccepPolicy isOpen={apModalIsOpen} onAccept={closeModal} />
      )}
    </div>
  );
};

export default BrandedApolloExplorer;
