import React from "react";
import Modal from "react-modal";
import styles from "../styles.module.css";

export interface AcceptPolicyProps {
  isOpen: boolean;
  onAccept: () => void;
}

const AcceptPolicyModal: React.FC<AcceptPolicyProps> = ({
  isOpen,
  onAccept,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onAccept}
      shouldCloseOnOverlayClick={false}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2>Accept Policy</h2>
      <p className={styles.paragraph}>
        This is a test environment with mock data, and the data model is subject
        to change. As a user, you acknowledge that the queries, mutations, and
        other operations may change over time.
        <br />
        <br />
        By clicking "Accept," you agree to use this environment with the
        understanding that changes may occur without prior notice.
      </p>
      <button className={styles.acceptButton} onClick={onAccept}>
        Accept
      </button>
    </Modal>
  );
};

export default AcceptPolicyModal;
