import React from "react";
import styles from "../styles.module.css";
import { ReactComponent as DaphneLogo } from "../assets/daphne-logo-bw.svg";

const AuthHeader: React.FC = () => {
  return (
    <div className={styles.authHeader}>
      <DaphneLogo />
    </div>
  );
};

export default AuthHeader;
